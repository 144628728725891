/* COOKIE FOR GDPR VALIDATION */
if (document.querySelectorAll('.cookie-validate').length > 0) {
	$('body').addClass('gv');
	document.querySelector('.cookie-validate').addEventListener('click', function (e) {
		e.preventDefault();
		Cookies.set('gdpr-validated', true, { expires: 90 });
		document.querySelector('.cookie-popin').classList.add('close');
		$('body').removeClass('gv');

		jQuery.get( PATH_TO_THEME + '/partials/analytics.php', function(analytics){
			$('body').append(analytics);
		}, 'html');
	});
}
if (Cookies.get('gdpr-validated') !== undefined && Cookies.get('gdpr-validated')) {
	Cookies.set('gdpr-validated', true, { expires: 90 });
}

// SLIDER
$(document).ready(function () {

	if ($('.slider').length > 0) {

		$('.slider').bxSlider({
			auto: true,
			stopAutoOnClick: false,
			pager: false,
			mode: 'fade',
			responsive: true,
			infiniteLoop: true,
			speed: 3000,
			controls: false
		});
	}

	lightbox.option({
		'alwaysShowNavOnTouchDevices': false,
		'imageFadeDuration': 1000,
		'disableScrolling': true
	});

	// detect IE8 and above, and edge
	if (document.documentMode || /Edge/.test(navigator.userAgent)) {
		document.querySelector('html').classList.add('ms');
	}
});

$(document).ready(function(){
	$('.burger').click(function(){
		$(this).toggleClass('open');
		$(".nav").toggleClass('open');
	});
});

//SMOOTH SCROLL TO
$(function() {
	$('a[href*="#"]:not([href="#"])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html, body').animate({
					scrollTop: target.offset().top
				}, 1500);
			return false;
			}
		}
	});
});


//MENU CHANGES ON SCROLL
/*$(window).on('scroll', function() {
	if(isFrontPage && $(window).width() > 960){
		var headerHeight = $( window ).height() - 1;
		if ($(window).scrollTop() > (headerHeight / 2)) {
			$(".nav").removeClass('open');
			$(".burger").removeClass('open');
			$(".bottom-buttons").addClass('menuClosed');
			// $(".burger").addClass("goUp");
		}
		else {
			$(".nav").addClass('open');
			$(".burger").addClass('open');
			$(".bottom-buttons").removeClass('menuClosed');
			// $(".burger").removeClass("goUp");
		}
	}
});*/
$(document).ready(function(){
	/*setTimeout(function(){
		if (isFrontPage && $(window).width() > 960){
			$(".nav").addClass('open');
			$(".burger").addClass('open');
			$(".bottom-buttons").removeClass('menuClosed');
		}
	},600);*/


	setTimeout(function(){
		$('.caroussel').slick({
		// arrows: false,
		autoplay: true,
		pauseOnHover: false
		});
	},1000);
	
});
